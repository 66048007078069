import { useState } from "react";
import DonateModal from "../modal/DonateModal";
import {
  imLixi,
  imLixi2,
  imLixi3,
  imMoney,
  imMoney2,
  imMoney3,
} from "../images";

const DonateComponent = () => {
  const [isOpenDonate, setOpenDonate] = useState(false);

  return (
    <div
      data-gjs-type="text"
      draggable="true"
      data-highlightable="1"
      data-id="donate-fab"
      id="call-to-donate"
    >
      <DonateModal
        isOpen={isOpenDonate}
        onCancel={() => setOpenDonate(false)}
      />
      <a
        onClick={() => setOpenDonate(!isOpenDonate)}
        data-gjs-type="link"
        data-highlightable="1"
        id="i64x93"
      >
        <div
          data-gjs-type="text"
          draggable="true"
          data-highlightable="1"
          class="sticky-btn-wrapper"
          id="ih56us"
        >
          <img
            data-gjs-type="image"
            src={imLixi}
            alt=""
            class="sticky-btn-front"
            id="i3dh83"
          />
          <svg
            data-gjs-type="svg"
            width="86"
            height="97"
            viewBox="0 0 86 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="sticky-btn-opener"
            id="ihmz4g"
          >
            <path
              data-gjs-type="svg-in"
              draggable="true"
              d="M85 52.445V81.3185C85 82.3228 84.526 83.2357 83.7675 83.893C83.3693 84.2399 82.8763 84.532 82.3454 84.7146L81.9472 84.8424L45.5788 96.1078C43.9291 96.6191 42.1278 96.6191 40.4781 96.1078L4.10971 84.8607L3.6167 84.7146C3.10474 84.532 2.6307 84.2399 2.23251 83.9112C1.47404 83.2357 1 82.3228 1 81.3185V52.445C1 50.1262 2.95305 48.2456 5.36117 48.2456H43H80.6388C83.047 48.2456 85 50.1262 85 52.445Z"
              fill="#A5072D"
              stroke="#231F20"
              stroke-miterlimit="10"
              id="ik49yh"
            ></path>
          </svg>
          <img
            data-gjs-type="image"
            src={imLixi2}
            alt=""
            class="sticky-btn-back"
            id="igqkcw"
          />{" "}
          <img
            data-gjs-type="image"
            src={imLixi3}
            alt=""
            class="sticky-btn-button"
            id="iof3uy"
          />{" "}
          <img
            data-gjs-type="image"
            src={imMoney3}
            alt=""
            class="sticky-btn-money1"
            id="i9pjf8"
          />{" "}
          <img
            data-gjs-type="image"
            src={imMoney2}
            alt=""
            class="sticky-btn-money2"
            id="ixn6d2"
          />{" "}
          <img
            data-gjs-type="image"
            src={imMoney}
            alt=""
            class="sticky-btn-money3"
            id="ib8hrz"
          />
        </div>
      </a>
    </div>
  );
};

export default DonateComponent;
