import { FilterOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  FloatButton,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Upload,
} from "antd";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { read, utils } from "xlsx";
import apiRepository from "../api/httpRepository";
import ConfirmImportModal from "../modal/ConfirmImportModal";
import GuestModal from "../modal/GuestModal";
import QRCodeModal from "../modal/QRCodeModal";
import { guestEmpty } from "../samples/guest";

const Admin = () => {
  const [query, setQuery] = useState();
  const [listImportData, setImportData] = useState();
  const [qrCodeItem, setQrCodeItem] = useState();
  const [guestItem, setGuestItem] = useState();
  const [guestData, setGuestData] = useState([]);

  const onImportFile = async (file) => {
    const ab = await file.arrayBuffer();
    const raw = read(ab);
    const ws = raw.Sheets[raw.SheetNames[0]];
    const data = utils.sheet_to_json(ws);
    setImportData(data);
    return false;
  };

  const convertQuery = useMemo(() => {
    const obj = {};
    if (!_.isEmpty(query)) {
      const splitKey = _.split(query, ",");
      for (let i = 0; i < splitKey.length; i++) {
        const item = splitKey[i];
        const itemSplit = _.split(item, ":");
        obj[itemSplit[0]] = itemSplit[1];
      }
    }
    return obj;
  }, [query]);

  const getListGuest = useCallback(() => {
    apiRepository
      .apiGetListGuest(convertQuery)
      .then(({ data }) => setGuestData(data));
  }, [convertQuery]);

  useEffect(() => {
    getListGuest();
  }, [getListGuest]);

  const columns = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (value) => <>{!!value ? value : "-"}</>,
    },
    {
      title: "Lời chúc",
      dataIndex: "wish",
      key: "wish",
      width: 500,
      render: (value) => <>{!!value ? value : "-"}</>,
    },
    {
      title: "Thời gian",
      key: "date",
      width: 200,
      render: (item) => (
        <>
          {item?.time} {item?.date}
        </>
      ),
    },
    {
      title: "Khách của",
      dataIndex: "guestOf",
      key: "guestOf",
      width: 100,
      render: (t) => <>{t === "ck" ? "Chồng" : "Vợ"}</>,
    },
    {
      title: "Ẩn/hiện lời chúc",
      dataIndex: "isShowWish",
      key: "isShowWish",
      width: 100,
      render: (isShow) => <Checkbox checked={isShow} />,
    },
    {
      title: "Tham dự",
      dataIndex: "isAttend",
      key: "isAttend",
      width: 100,
      render: (isAttend) => {
        const color = isAttend ? "green" : "volcano";
        return (
          <Tag color={color}>{isAttend ? "Có tham dự" : "Không tham dự"}</Tag>
        );
      },
    },
    {
      title: `Tổng: ${guestData.length}`,
      width: 150,
      key: "actions",
      render: (item) => {
        delete item.key;
        return (
          <Space size="middle">
            <a onClick={() => setQrCodeItem(item)}>Xem mã</a>
            <a onClick={() => setGuestItem(item)}>Sửa</a>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="admin-page">
      <Row className="top-content">
        <Space>
          <div style={{ display: "flex" }}>
            <Input
              onPressEnter={getListGuest}
              style={{ width: 500, marginRight: 8 }}
              value={query}
              onChange={({ target }) => setQuery(target.value)}
              placeholder="Nhập bộ lọc"
            />
            <Button
              onClick={getListGuest}
              type="dashed"
              icon={<FilterOutlined />}
            >
              Lọc
            </Button>
          </div>
          <Button type="primary" onClick={() => setGuestItem(guestEmpty)}>
            Thêm khách mời
          </Button>
          <Upload
            multiple={false}
            showUploadList={false}
            accept=".xlsx, .xls"
            beforeUpload={onImportFile}
          >
            <Button type="dashed" icon={<UploadOutlined />}>
              Nhập file excel
            </Button>
          </Upload>
        </Space>
      </Row>
      <Table
        sticky={{ offsetHeader: 0 }}
        pagination={{ pageSize: guestData.length, hideOnSinglePage: true }}
        className="guest-table"
        columns={columns}
        scroll={{ x: true }}
        dataSource={_.map(guestData, (guest) => ({ ...guest, key: guest.id }))}
      />
      <QRCodeModal item={qrCodeItem} onCancel={() => setQrCodeItem(null)} />
      <GuestModal
        guest={guestItem}
        onCancel={() => setGuestItem(null)}
        onUpdated={getListGuest}
      />
      <ConfirmImportModal
        listData={listImportData}
        onImported={getListGuest}
        onCancel={() => setImportData(null)}
      />
      <FloatButton.BackTop visibilityHeight={200} />
    </div>
  );
};

export default Admin;
