import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  TimePicker,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import apiRepository from "../api/httpRepository";
import useGlobalStage from "../hooks/useGlobalStage";
import dayjs from "dayjs";
import { dateFormat, timeFormat } from "../utils/time";
import _ from "lodash";

const { TextArea } = Input;

const GuestModal = ({ guest, onCancel, onUpdated }) => {
  const [guestLocal, setGuestLocal] = useState();
  const { userInfo } = useGlobalStage();

  useEffect(() => {
    setGuestLocal(_.omit(guest, ["createdAt", "updatedAt"]));
  }, [guest, setGuestLocal]);

  const onUpdateGuest = useCallback(async () => {
    if (!guestLocal.name) {
      message.error("Nhập tên khách mời!");
      return;
    }
    if (guestLocal?.id) {
      await apiRepository.apiUpdateGuest(guestLocal);
    } else {
      await apiRepository.apiCreateGuest({
        ...guestLocal,
        guestOf: userInfo.guestOf,
      });
    }
    onCancel();
    onUpdated();
  }, [guestLocal, userInfo, onCancel, onUpdated]);

  return (
    <Modal
      open={!!guest}
      okText="Tải về"
      className="qr-code-modal"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Hủy
        </Button>,
        <Button onClick={onUpdateGuest} key="update" type="primary">
          {guestLocal?.id ? "Cập nhật" : "Thêm"}
        </Button>,
      ]}
    >
      <div>
        <Row>
          <Col span={11}>
            <p>Tên khách:</p>
            <Input
              placeholder="Nhập tên khách"
              value={guestLocal?.name}
              onChange={({ target }) =>
                setGuestLocal({ ...guestLocal, name: target.value })
              }
            />
          </Col>
          <Col span={11} offset={2}>
            <p>Mô tả:</p>
            <Input
              placeholder="Nhập mô tả"
              value={guestLocal?.description}
              onChange={({ target }) =>
                setGuestLocal({ ...guestLocal, description: target.value })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <p>Giờ:</p>
            <TimePicker
              allowClear={false}
              format={timeFormat}
              value={dayjs(guestLocal?.time, timeFormat)}
              onChange={(_, time) => setGuestLocal({ ...guestLocal, time })}
            />
          </Col>
          <Col span={11} offset={2}>
            <p>Ngày:</p>
            <DatePicker
              allowClear={false}
              value={dayjs(guestLocal?.date, dateFormat)}
              format={dateFormat}
              onChange={(_, date) => setGuestLocal({ ...guestLocal, date })}
            />
          </Col>
        </Row>
        <p>Lời chúc:</p>
        <TextArea
          rows={4}
          placeholder="Nhập lời chúc"
          value={guestLocal?.wish}
          onChange={({ target }) =>
            setGuestLocal({ ...guestLocal, wish: target.value })
          }
        />
        <Row>
          <Col span={11}>
            <p>Ẩn/Hiện lời chúc:</p>
            <Checkbox
              checked={guestLocal?.isShowWish}
              onChange={({ target }) =>
                setGuestLocal({ ...guestLocal, isShowWish: target.checked })
              }
            />
          </Col>
          <Col span={11} offset={2}>
            <p>Tham dự:</p>
            <Checkbox
              checked={guestLocal?.isAttend}
              onChange={({ target }) =>
                setGuestLocal({ ...guestLocal, isAttend: target.checked })
              }
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default GuestModal;
