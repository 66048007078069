import { Button, Form, Input } from "antd";
import { useCallback } from "react";
import httpRepository from "../api/httpRepository";
import { setToken } from "../utils/storage";

const Login = () => {
  const onFinish = useCallback(async (values) => {
    const { data } = await httpRepository.apiLogin(values);
    if (!data) return;
    setToken(data.token);
    window.location.href = "/admin";
  }, []);

  const onFinishFailed = useCallback((errorInfo) => {
    console.log("Failed:", errorInfo);
  }, []);

  return (
    <div className="login-page">
      <Form
        name="login"
        layout="vertical"
        style={{
          width: 500,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="user"
          rules={[
            {
              required: true,
              message: "Nhập username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Nhập password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ float: "right" }}>
          <Button type="primary" htmlType="submit">
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
