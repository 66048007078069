import {
  anh1,
  anh2,
  anh3,
  anh4,
  anh5,
  anh6,
  anh7,
  anh8,
  anh9,
  anh10,
  anh11,
  anh12,
  anh13,
} from "../images";

export const photos = [
  {
    src: anh1,
    width: 4,
    height: 6,
  },
  {
    src: anh2,
    width: 4,
    height: 6,
  }, {
    src: anh3,
    width: 4,
    height: 6,
  }, {
    src: anh4,
    width: 4,
    height: 6,
  }, {
    src: anh5,
    width: 4,
    height: 6,
  }, {
    src: anh6,
    width: 4,
    height: 6,
  }, {
    src: anh7,
    width: 4,
    height: 6,
  }, {
    src: anh8,
    width: 4,
    height: 6,
  }, {
    src: anh9,
    width: 4,
    height: 6,
  }, {
    src: anh10,
    width: 4,
    height: 6,
  }, {
    src: anh11,
    width: 4,
    height: 6,
  }, {
    src: anh12,
    width: 4,
    height: 6,
  }, {
    src: anh13,
    width: 4,
    height: 6,
  },
];
