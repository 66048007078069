

const AddressComponent = ({ guest }) => {
  let coordinates = "18.278501,106.002998"; // Tọa độ mặc định
  if (guest?.guestOf === "vk") {
    coordinates = "18.278501,106.002998";}
  return (
    <>
      {
        <>
          <h4 style={{ marginTop: 50 }}>
            Tại địa chỉ {`nhà ${guest?.guestOf === "vk" ? "trai" : "trai"}`}
          </h4>
          <h2>
            {guest?.guestOf === "vk" ? "Tổ dân phố 7" : "Tổ dân phố 7"}, Thị Trấn Cẩm Xuyên, Huyện Cẩm Xuyên, Hà Tĩnh
          </h2>

          <a
            href={`https://www.google.com/maps/search/?api=1&query=${coordinates}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps
          </a>
          <p className="welcome-text">
            Sự hiện diện của bạn sẽ là niềm vinh hạnh lớn của chúng tôi!
          </p>
        </>
      }
    </>
  );
};

export default AddressComponent;
