import { List, Modal, message } from "antd";
import { useCallback } from "react";
import apiRepository from "../api/httpRepository";

const ConfirmImportModal = ({ listData, onCancel, onImported }) => {
  const onImport = useCallback(async () => {
    await apiRepository.apiCreateMultiGuest(listData);
    message.success("Tạo khách mời thành công");
    onCancel();
    onImported();
  }, [listData, onCancel, onImported]);

  return (
    <Modal
      title
      open={!!listData}
      cancelText="Hủy"
      onCancel={onCancel}
      onOk={onImport}
      className="qr-code-modal"
      width={1000}
    >
      <p>Tổng khách mời: {listData?.length}</p>
      <List
        dataSource={listData}
        renderItem={(item, idx) => (
          <List.Item key={idx}>
            <List.Item.Meta
              title={`${item.name}`}
              description={`${item.time} ${item.date}`}
            />
            <div>{item.wish}</div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ConfirmImportModal;
