import { CloudDownloadOutlined } from "@ant-design/icons";
import { Flex, FloatButton, QRCode } from "antd";
import { useCallback, useEffect, useState } from "react";
import apiRepository from "../api/httpRepository";
import html2canvas from "html2canvas";
import applicationConfigs from "../config";

const RenderCode = () => {
  const [listGuest, setGuestData] = useState([]);

  useEffect(() => {
    apiRepository.apiGetListGuest({}).then(({ data }) => setGuestData(data));
  }, []);

  const onDownloadWeddingCard = useCallback(async () => {
    const htmlRaw = document.getElementById("render-code");
    const canvas = await html2canvas(htmlRaw);
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "render-code.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, []);

  return (
    <div className="home-page">
      <FloatButton
        type="primary"
        icon={<CloudDownloadOutlined />}
        onClick={onDownloadWeddingCard}
        tooltip={<div>Tải về</div>}
      />
      <Flex id="render-code" vertical={false} wrap="wrap" gap="small">
        {listGuest.map((item) => (
          <div style={{ width: 160 }}>
            <p>{item?.name}</p>
            <QRCode
              bgColor="#fff"
              value={`${applicationConfigs.webUrl}?id=${item?.id}`}
            />
          </div>
        ))}
      </Flex>
    </div>
  );
};

export default RenderCode;
