import axios from "axios";
import applicationConfigs from "../config";
import { getToken } from "../utils/storage";

const api = axios.create({
  timeout: 5000,
  baseURL: applicationConfigs.baseUrl,
});

api.interceptors.request.use(async (config) => {
  const accessToken = getToken()
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    },
  };
});

export default api;
