import { message } from "antd";
import api from ".";
import { removeToken } from "../utils/storage";

const httpMethod = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
};

const catchApi = async (method, path, body, query) => {
  let apiObj;
  switch (method) {
    case httpMethod.POST:
      apiObj = api.post(path, body);
      break;
    case httpMethod.PATCH:
      apiObj = api.patch(path, body);
      break;
    default:
      apiObj = api.get(path, { params: query });
      break;
  }
  try {
    return await apiObj;
  } catch ({ response }) {
    if (response.status === 401) {
      removeToken();
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }
    return await message.error(response.data);
  }
};

class httpRepository {
  // Guest
  apiGetGuestInfo = (id) => catchApi(httpMethod.GET, `?id=${id}`, null);
  apiGetListWish = (id) => catchApi(httpMethod.GET, `guests`, null);
  apiUpdateGuestInfo = (body) => catchApi(httpMethod.PATCH, "/", body);
  // Admin
  apiLogin = (body) => catchApi(httpMethod.POST, "admin/login", body);
  apiGetUserInfo = () => catchApi(httpMethod.GET, "admin/info", null);
  apiGetListGuest = (query) => catchApi(httpMethod.GET, "admin/guests", null, query);
  apiCreateGuest = (body) => catchApi(httpMethod.POST, "admin/createGuest", body);
  apiCreateMultiGuest = (body) => catchApi(httpMethod.POST, "admin/createMultiGuest", body);
  apiUpdateGuest = (body) => catchApi(httpMethod.PATCH, "admin/updateGuest", body);
}

const apiRepository = new httpRepository();

export default apiRepository;
