import moment from "moment";

export const timeFormat = "HH:mm";
export const dateFormat = "DD/MM/YYYY";
export const string2Date = (dateString = "25/02/2024") => {
  console.log(dateString);
  const date = moment.utc(dateString, dateFormat);
  const day = date.format("DD");
  const month = date.format("MM");
  const year = date.format("YYYY");
  const dayOfWeek = date.isoWeekday();
  return { day, month, year, dayOfWeek: numberOfWeek(dayOfWeek) };
};

const numberOfWeek = (dayNumber) => {
  let day = "";
  switch (dayNumber) {
    case 1:
      day = "Thứ 2";
      break;
    case 2:
      day = "Thứ 3";
      break;
    case 3:
      day = "Thứ 4";
      break;
    case 4:
      day = "Thứ 5";
      break;
    case 5:
      day = "Thứ 6";
      break;
    case 6:
      day = "Thứ 7";
      break;
    default:
      day = "Chủ Nhật";
      break;
  }
  return day;
};
