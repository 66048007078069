import { useEffect, useState } from "react";
import apiRepository from "../api/httpRepository";

const useGlobalStage = () => {
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    apiRepository.apiGetUserInfo().then(({ data }) => setUserInfo(data));
  }, []);

  return { userInfo };
};

export default useGlobalStage;
