import { CopyOutlined } from "@ant-design/icons";
import { Button, Modal, QRCode, message } from "antd";
import html2canvas from "html2canvas";
import { useCallback } from "react";
import applicationConfigs from "../config";

const QRCodeModal = ({ item, onCancel }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const onDownloadQrCode = useCallback(async () => {
    const htmlRaw = document.getElementById("qr-code-content");
    const canvas = await html2canvas(htmlRaw);
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, []);

  const onCopyLink = useCallback(async () => {
    const guestLink = document.getElementById("guest-link");
    messageApi.open({
      type: "success",
      content: "Đã copy",
    });
    navigator.clipboard.writeText(guestLink);
  }, [messageApi]);

  const onOpenLink = useCallback(async (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }, []);

  return (
    <Modal
      open={!!item}
      onCancel={onCancel}
      okText="Tải về"
      onOk={onDownloadQrCode}
      className="qr-code-modal"
    >
      {contextHolder}
      <div id="qr-code-content">
        <h1>{item?.name}</h1>
        <QRCode
          bgColor="#fff"
          value={`${applicationConfigs.webUrl}?id=${item?.id}`}
          style={{
            marginBottom: 16,
          }}
        />
      </div>
      <a
        id="guest-link"
        onClick={() =>
          onOpenLink(`${applicationConfigs.webUrl}?id=${item?.id}`)
        }
        href={`/?id=${item?.id}`}
      >
        {applicationConfigs.webUrl}?id={item?.id}
      </a>
      <Button
        onClick={onCopyLink}
        style={{ marginLeft: 20 }}
        icon={<CopyOutlined />}
      />
      <Button
        onClick={() =>
          onOpenLink(`${applicationConfigs.webUrl}/wedding-card?id=${item?.id}`)
        }
      >
        Xem thiệp mời
      </Button>
    </Modal>
  );
};

export default QRCodeModal;
